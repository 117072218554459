@import '@we-make-websites/styles-lib/src/config/configuration';
.hero {
  $parent: &;

  &__slider {
    width: 100vw;
    position: relative;

    &--loading {
      :global(.keen-slider__slide:not(:first-of-type)) {
        display: none;
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  &__video {
    @include aspect-ratio($force: true);
    // 16 / 9 aspect ratio
    --ratio-percent: 56.25%;
    height: auto;
    width: 100%;
  }

  &__mobileImageWrapper {
    position: relative;
    height: 60vw;
  }

  &__image {
    width: 100%;
    height: auto;

    &--mobileCover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top left;
    }

    &--desktop {
      display: none;
    }
  }

  &__subheading {
    @include text-body-eyebrow;
    @include fluid-type(48rem, 90rem, 0.75rem, 1.125rem);
  }

  &__heading {
    @include text-heading-xl;
    @include fluid-type(48rem, 90rem, 2.5rem, 4.6875rem);
    line-height: 1em;
    margin-top: 0.2em;
    text-indent: 1.2em;
    white-space: pre-line;
  }

  &__contentTextReverseTitle {
    display: flex;
    flex-direction: column;
  }

  &__message {
    @include text-heading-h4;
    @include fluid-type(48rem, 90rem, 1rem, 1.375rem);
    margin-top: 0.5em;
  }

  &__button {
    margin-top: var(--spacing-m);
  }

  &__text {
    @include mq($from: m) {
      color: var(--color-white);
    }
  }
  &__text--mobile {
    @include mq($until: m) {
      color: var(--color-white);
    }
  }

  &__slideForeground {
    max-width: 100vw;
    position: relative;
    padding: var(--spacing-s) var(--spacing-4xl) var(--spacing-4xl)
      var(--spacing-m);

    &--single {
      padding: var(--spacing-s) var(--spacing-4xl) var(--spacing-s)
        var(--spacing-m);
    }

    &--imageCover {
      z-index: 1;
    }
  }

  &__overlayImageWrapper {
    @include aspect-ratio($force: true);
    // 310 / 422 aspect ratio
    --ratio-percent: calc(422 / 310 * 100%);
    position: absolute;
    right: 32px;
    bottom: 0;
    width: 25vw;
  }

  &__disclaimer {
    margin-top: var(--spacing-xl);
  }

  &__arrow {
    position: absolute;
  }

  &__dots {
    position: absolute;
    left: 0;
    bottom: 0;
    height: var(--spacing-3xl);
    width: fit-content;
    display: flex;
    align-items: center;
    z-index: var(--layer-flat);
  }

  &__dotsBackground {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--spacing-3xl);
  }

  &__slidePipButton {
    all: unset;
    cursor: pointer;
    padding: var(--spacing-xs);
  }

  &__dot {
    width: 6px;
    height: 6px;
    background: var(--color-light-grey);
    border-radius: 50%;
    display: block;

    &--active {
      background: var(--color-background-dark);
    }
  }

  &__navButton {
    all: unset;
    cursor: pointer;
    position: absolute;
    display: none;
    top: 50%;
    margin-top: -20px;

    :global(.icon) {
      width: 29px;
      height: 29px;
    }

    &--prev,
    &--nextReverse {
      left: 50px;

      :global(.icon) {
        transform: rotate(180deg);
      }
    }

    &--next,
    &--prevReverse {
      right: 50px;
    }
  }

  &__animated {
    animation: slide-fade var(--timing-slow) var(--easing-normal);
  }

  @include mq($until: m) {
    &__slider {
      margin-bottom: var(--spacing-xl);
    }
  }

  @include mq($from: m) {
    &__slide {
      min-height: 37vw;
      height: auto;
      flex-direction: row-reverse;

      &.withMargins {
        margin-block-end: var(--spacing-5xl);
        margin-block-start: var(--spacing-5xl);
      }
    }

    &__mobileImageWrapper {
      display: none;
    }

    &__video,
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top left;

      &--desktopCover {
        object-fit: cover;
      }

      &--desktop {
        display: initial;
      }

      &--mobile {
        display: none;
      }
    }

    &__overlayImageWrapper {
      width: 17vw;
    }

    &__videoButton {
      /* Doubling specificity - When I don't nest it didn't work */
      &#{&} {
        bottom: var(--spacing-6xl);
        left: unset;
        right: var(--spacing-3xl);
        top: unset;
        transform: unset;
      }
    }

    &__slideForeground {
      width: 50%;
      padding: var(--spacing-xl) var(--spacing-3xl) var(--spacing-5xl) 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &--single {
        margin-bottom: 0 !important;
      }

      &--imageCover {
        margin-top: 0;
        margin-bottom: 64px;
      }
    }

    &__disclaimer {
      position: absolute;
      bottom: var(--spacing-xl);
      margin-top: 0;
    }

    &__dots {
      height: var(--spacing-5xl);
    }

    &__dot {
      width: 9px;
      height: 9px;
    }

    &__dotsBackground {
      height: var(--spacing-5xl);
    }

    &__hide {
      display: none;
    }
  }

  @include mq($from: l) {
    &__overlayImageWrapper {
      width: 12vw;
    }
  }

  @media (hover: hover) {
    &__navButton {
      display: block;
      transition: transform 0.5s ease-in,
        opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
      transform: scale(0.8);
      opacity: 0;
    }

    &:hover {
      #{$parent}__navButton {
        transform: scale(1);
        opacity: 0.5;

        &:not([disabled]) {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes slide-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
