@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Video Player
 * -----------------------------------------------------------------------------
 * Base styles for the Video Player component.
 *
 */
.videoPlayer {
  $parent: &;
  position: relative;
  width: 100%;

  &:global(.is-loading) {
    @include loading();
    animation-name: pulse;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__button {
    @include button-reset;
    line-height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: var(--layer-flat);

    svg {
      height: 66px;
      width: 66px;
    }
  }

  &__poster {
    object-fit: contain;
  }

  &__el {
    height: 100%;
    width: 100%;
  }

  &__embed {
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  @include mq($from: l) {
    height: auto;
    aspect-ratio: 592 / 362.5 !important;

    &:global(.is-loading) {
      height: auto;
      aspect-ratio: 592 / 362.5 !important;
    }

    &__button svg {
      height: 122px;
      width: 122px;
    }

    &__poster {
      object-fit: cover;
    }
  }
}
